import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Website from "./webpage/Website.js";
import TermsAndConditions from "./webpage/TermsAndConditions.js";
import PrivacyAndPolicy from "./webpage/PrivacyAndPolicy.js";
import ShippingPolicy from "./webpage/ShippingPolicy.js";
function App() {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Website />} />
        <Route path="/termCondition" element={<TermsAndConditions />} />
        <Route path="/PrivacyPolicy" element={<PrivacyAndPolicy />} />
        <Route path="/shipping" element={<ShippingPolicy />} />
        {/* Catch all unknown routes */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
