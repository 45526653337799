import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaPhone,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import "./styles/Footer.css";
import { ReactComponent as AppLogo } from "../assets/Logo.svg";
import UnsubscribeModal from "./unsubscribeModal.js";
import { useNavigate } from "react-router-dom";
import logo from "../assets/GamaLogo.png";

function Footer() {
  const navigate = useNavigate();
  const [isOpenUnsubscribeModal, setIsOpenUnsubscribeModal] = useState(false);
  const toggleUnsubscribeModal = () =>
    setIsOpenUnsubscribeModal(!isOpenUnsubscribeModal);
  return (
    <div id="contact" className="footer-section ">
      <Container className="footer-container">
        <Row>
          {/* Logo Section */}
          {/* Logo Section */}
          <Col md="4" className="footer-logo" style={{ textAlign: "left" }}>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                padding: "10px", // White circular background
                width: "80px", // Outer container width
                height: "80px", // Outer container height
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <AppLogo
                style={{
                  width: "95%", // Reduce logo size inside the circle
                  height: "95%",
                }}
              />
            </div>
            <h5 style={{ marginTop: "5px", fontWeight: "bold" }}>Gama Gamaa</h5>
          </Col>

          {/* Company Links */}
          <Col md="4" className="footer-links">
            <h5>Company</h5>
            <ul>
              <li>
                <a href="#about">About Us</a>
              </li>
              <li>
                <a href="#locations">Locations</a>
              </li>
              <li>
                <a
                  onClick={() => {
                    navigate("/termCondition");
                  }}
               role="button"
                >
                  Terms of Use
                </a>
              </li>
              {/* <li>
                <a onClick={toggleUnsubscribeModal} style={{cursor:"pointer"}}> Account Delete Request</a>
              </li> */}
              <li>
                <a
                  onClick={() => {
                    navigate("/PrivacyPolicy");
                  }}
                  role="button"
                >
                  Privacy Policy
                </a>
              </li>
              {/* <li>
                <a>Refund & Cancellation Policy</a>
              </li> */}
              <li>
                <a
                  onClick={() => {
                    navigate("/shipping");
                  }}
                 role="button"
                >
                  Shipping & Delivery Policy
                </a>
              </li>
            </ul>
          </Col>

          {/* Address Section */}
          <Col md="4" className="footer-address">
            <h5>Address</h5>
            <p>
              <p>
                <FaMapMarkerAlt />{" "}
                <span style={{ marginRight: "8px", fontSize: "1.1rem" }}>
                  Location
                </span>
                <br />{" "}
                <a
                  href="https://www.google.com/maps?q=12.829262494679835,80.22997988650809"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  View on Map
                </a>
              </p>
            </p>
            <p style={{ textAlign: "left" }}>
              91/18A, Thiruporur block,
              <br />
              Egattur, Tamil Nadu <br />
              603103
            </p>
            <h6>Contact Us</h6>
            <p style={{ textAlign: "left" }}>
              <FaPhoneAlt /> 9606 455 677
            </p>
          </Col>

          {/* Social Media */}
          {/* <Col md="3" className="footer-social">
            <h5 >Follow Us</h5>
            <div className="social-icons">
              <a href="#">
                <FaFacebookF />
              </a>
              <a href="#">
                <FaTwitter />
              </a>
              <a href="#">
                <FaInstagram />
              </a>
            </div>
          </Col> */}
        </Row>

        {/* Terms & Conditions Unsubscribe Section */}
        {/* <Row>
          <Col className="unsubscribe-section text-center">
            <h6 onClick={toggleUnsubscribeModal} style={{cursor:"pointer"}}>Tell us if you wish to unsubscribe and remove your account</h6>
          </Col>
        </Row> */}
        {/* Copyright */}
        <Row>
          <Col className="text-center">
            <p className="copyright">©2025 by Gama Gamaa</p>
          </Col>
        </Row>
      </Container>
      <UnsubscribeModal
        isOpen={isOpenUnsubscribeModal}
        toggle={toggleUnsubscribeModal}
      />
    </div>
  );
}

export default Footer;
